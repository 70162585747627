<template>
  <div id="level">
    <el-dialog
      title="修改等级规则"
      width="360px"
      :visible.sync="levelDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="levelFormRef"
        :model="levelForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="等级名称">
              <el-input v-model="levelName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分大于" prop="min">
              <el-input v-model="levelForm.min" placeholder="请输入积分大于" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分小于" prop="max">
              <el-input v-model="levelForm.max" placeholder="请输入积分小于" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="levelDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="levelFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-table
      :data="levelList"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
    >
      <el-table-column prop="levelName" label="等级名称" />
      <el-table-column prop="min" label="积分大于" />
      <el-table-column prop="max" label="积分小于" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { updateLevel, selectLevelList } from '@/api/main/level'

export default {
  data () {
    return {
      levelDialogVisible: false,
      levelForm: {
        id: '',
        min: '',
        max: ''
      },
      levelList: [],
      levelName: ''
    }
  },
  created () {
    selectLevelList().then(res => {
      this.levelList = res.list
    })
  },
  methods: {
    levelFormSubmit () {
      this.$refs.levelFormRef.validate(async valid => {
        if (valid) {
          await updateLevel(this.levelForm)
          this.levelList = (await selectLevelList()).list
          this.levelDialogVisible = false
        }
      })
    },
    handleUpdate (index, row) {
      this.levelDialogVisible = true
      this.levelForm.id = row.id
      this.levelName = row.levelName
      this.levelForm.min = row.min
      this.levelForm.max = row.max
    }
  }
}
</script>

<style>

</style>
