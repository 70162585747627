import axios from '@/common/axios'
import qs from 'qs'

export function updateLevel (data) {
  return axios({
    method: 'put',
    url: '/level/update',
    data: qs.stringify(data)
  })
}

export function selectLevelList () {
  return axios({
    method: 'get',
    url: '/level/list'
  })
}
